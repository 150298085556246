<template>
  <section class="card-house flex flex-warp">
    <div
      class="house-item"
      :class="`room_status_${item.roomStatus}`"
      :style="{
        // 87 137 200
        backgroundColor: item.rentStatus === '装修' ? 'rgb(195 195 195)' :
                          (item.rentStatus === '已租' ? 'rgb(87 137 200)' : 'rgb(131 202 234)')
      }"
      v-for="item in data"
      :key="item.apartmentUuid"
      @click="handleClickHouse(item)"
    >
      <div class="header flex align-center">
        <div class="house-name">{{ item.roomNum }}</div>
        <div class="lessee-number">（{{ item.leasorNum || 0 }}人）</div>
      </div>
      <div class="main">
        <div
          class="lessee-item flex align-center"
          v-for="lessee in houseOptions"
          :key="`${item.uuid}_${lessee.name}`"
        >
          <div class="label">{{ lessee.label }}：</div>
          <div class="value">
            <span>{{ item[lessee.name] }}</span>
            <span v-if="lessee.name === 'leasorName'">
              {{ item.leasorPhone }}</span
            >
            <span v-if="item[lessee.name]"> {{ lessee.unit }}</span>
          </div>
        </div>
      </div>
      <div class="footer flex align-center justify-end">
<!--        2023-11-28-陈守亮-暂时隐藏水费电费--->
<!--        <el-tooltip placement="top" effect="light">-->
<!--          <div slot="content">{{ item.waterAmount }}</div>-->
<!--          <img-->
<!--            class="footer-icon"-->
<!--            :title="createIcon('water', item.water).title"-->
<!--            :src="createIcon('water', item.water).src"-->
<!--            alt=""-->
<!--          />-->
<!--        </el-tooltip>-->

<!--        <el-tooltip placement="top" effect="light">-->
<!--          <div slot="content">{{ item.electAmount }}</div>-->
<!--          <img-->
<!--            class="footer-icon"-->
<!--            :title="createIcon('elect', item.elect).title"-->
<!--            :src="createIcon('elect', item.elect).src"-->
<!--            alt=""-->
<!--          />-->
<!--        </el-tooltip>-->
        <img
          class="footer-icon"
          :title="createIcon('intelLock', item.intelLock).title"
          :src="createIcon('intelLock', item.intelLock).src"
          alt=""
        />
      </div>

      <div class="room-status">
        <el-badge v-if="item.vacancyDay === null" type="warning">
          <el-tag
            :type="createRoomStatus(item.state).type"
            size="medium"
            effect="dark"
          >
            {{item.rentStatus? item.rentStatus : "暂无"}}
          </el-tag>
        </el-badge>
        <el-badge :value="item.vacancyDay" v-else type="warning">
          <el-tag
            :type="createRoomStatus(item.state).type"
            size="medium"
            effect="dark"
          >
            {{ createRoomStatus(item.state).codeName }}
          </el-tag>
        </el-badge>
        <div style="height: 10px"></div>
        <el-tag
          type="success"
          size="medium"
          effect="dark"
          v-if="item.isLetOutRent === 1"
          >不对外</el-tag
        >
      </div>
    </div>
  </section>
</template>

<script>
import {
  houseOptions,
  houseDevice,
} from "@/views/rental-management/house-resource/data";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    houseStatus: {
      type: Array,
      default: () => [],
    },
  },
  name: "card-house",
  data() {
    return {
      houseOptions,
    };
  },
  methods: {
    //房源卡片点击事件
    handleClickHouse(item) {
      //调用上层传递方法
      this.$emit("on-click", item);
    },
    // 获取水、电、智能锁状态图标
    createIcon(name, boolean) {
      // 水、电、智能锁状态图标
      const iconName = boolean ? "" : "_bed";
      const statusName = boolean ? "正常" : "损坏";
      const { label } = houseDevice.find((v) => v.name === name);
      const title = label + statusName;
      const src = require(`../../../../assets/images/house/${name}${iconName}.png`);
      return { title, src };
    },
    // 获取房间状态
    createRoomStatus(status) {
      // 房间状态
      if (!this.houseStatus.map((v) => v.code).includes(status)) return "--";
      const { codeName, dataValue } = this.houseStatus.find(
        (v) => v.code === status
      );
      return { type: dataValue, codeName };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-house {
  .house-item {
    min-width: 140px;
    min-height: 135px;
    width: VW(270px);
    height: VH(160px);
    color: #fff;
    border-radius: 4px;
    background: #68acfd;
    padding: VH(10px) VW(20px);
    margin: 0 0 VH(20px) VW(20px);
    position: relative;
    cursor: pointer;

    .header {
      padding-bottom: VH(10px);

      .house-name {
        font-size: rem(25px);
      }
    }

    .main {
      opacity: 0.63;
      font-size: rem(13px);

      .lessee-item {
        padding-bottom: VH(5px);
      }
    }

    .footer-icon {
      width: VW(14px);
      padding-right: VW(5px);
    }

    .room-status {
      font-size: rem(14px);
      position: absolute;
      top: VH(10px);
      right: VW(20px);
    }
  }
}
</style>
